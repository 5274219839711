(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('LogsController', LogsController);

    LogsController.$inject = ['ActuatorLoggers'];

    function LogsController(ActuatorLoggers) {
        const vm = this;

        vm.loggers = null;

        load();

        function load() {
            ActuatorLoggers.list().then(data => {
                vm.loggers = [];
                for (const [name, config] of Object.entries(data.loggers)) {
                    vm.loggers.push({ name, level: config.effectiveLevel });
                }
            });
        }

        vm.changeLevel = (name, level) => {
            ActuatorLoggers.update(name, level).then(() => {
                load();
            });
        }
    }
})();
