(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Pecam', Pecam);

    Pecam.$inject = ['$rootScope', '$http'];

    function Pecam($rootScope, $http) {

        return {
            queryTelemonitoring,
            queryTelemonitoringRequestsByPatient,
            queryTelemonitoringRequests,
            startTelemonitoring,
            prescribeRequests,
            hasValidTelemonitoringPrescription,
        };

        function queryTelemonitoring(patientId) {
            if (!patientId) {
                throw new Error("Patient id is required");
            }
            return $http.get('api/telemonitoring', {params: {patientId}});
        }

        function queryTelemonitoringRequestsByPatient(patientId) {
            if (!patientId) {
                throw new Error("Patient id is required");
            }
            return queryTelemonitoringRequests({patientId});
        }

        function queryTelemonitoringRequests(params) {
            return $http.get('api/telemonitoring-requests', {params});
        }

        function startTelemonitoring(prescriptionId) {
            if (!prescriptionId) {
                throw new Error("Patient id is required");
            }
            return $http.post('api/telemonitoring/start', {prescriptionId});
        }

        function prescribeRequests(requestIds) {
            return $http.post('api/telemonitoring-requests/prescribe', {requestIds})
        }

        function hasValidTelemonitoringPrescription(patientId) {
            return $http.get('api/telemonitoring-prescriber/has-valid-telemonitoring-prescription', { params: { patientId } });
        }
    }

})();
