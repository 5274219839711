(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('FacilityPlanningController', FacilityPlanningController);

    FacilityPlanningController.$inject = ['$scope', '$log', '$uibModal', 'filters', 'Principal', 'Visit', 'DownloadFile', 'HealthFacility', 'Practitioner'];

    function FacilityPlanningController($scope, $log, $uibModal, filters, Principal, Visit, DownloadFile, HealthFacility, Practitioner) {

        const vm = this;

        vm.planningDates = [];
        vm.selectedDate = null;
        vm.filters = filters;
        vm.loadingVisit = true;
        vm.filteredVisits = null;
        vm.visitStatuses = Object.keys(filters.status);
        vm.visitTypes = null;
        resetCountByStatus();

        vm.onSelectedDate = onSelectedDate;
        vm.onClickNextWeek = onClickNextWeek;
        vm.onClickPreviousWeek = onClickPreviousWeek;
        vm.onClickNextDay = onClickNextDay;
        vm.onClickPreviousDay = onClickPreviousDay;
        vm.plan = plan;
        vm.exportPdf = exportPdf;

        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;

        vm.$onInit = () => {
            const monday = moment().startOf('week');
            initWeekPlanningDate(monday);

            getAccount()
                .then((account) => {

                    vm.visitTypes = getVisitTypeForUser();

                    if(account.settings && account.settings.diseaseDivisionName) {
                        // load disease division name from account settings
                        vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                    }

                    let promise;
                    if(account.practitioner && account.practitioner.healthFacilityId) {
                        promise = loadDiseaseDivisions(account.practitioner.healthFacilityId);
                    } else {
                        promise = Promise.resolve();
                    }
                    promise.then(() => {

                        vm.unwatchTherapyType = $scope.$watch('vm.filters.therapyType', (newVal, oldVal) => {
                            if (newVal === oldVal) {
                                return;
                            }
                            loadVisits();
                        });

                        vm.unwatchStatus = $scope.$watch('vm.filters.status', onChangeFilter, true);

                        vm.unwatchVisitType = $scope.$watch('vm.filters.visitType', onChangeFilter);
                        if(vm.isCoordination) {
                            loadUnicorns();
                        }

                        loadVisits();
                    });

                });
        };

        vm.$onDestroy = () => {
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchStatus) {
                vm.unwatchStatus();
            }
            if (vm.unwatchVisitType) {
                vm.unwatchVisitType();
            }
            if (vm.unwatchFeasibleByPractitionerId) {
                vm.unwatchFeasibleByPractitionerId();
            }
        };

        function loadDiseaseDivisions(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                facility.diseaseDivisions = facility.diseaseDivisions || [];

                vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                // group by division name reduce to 1 division by name
                vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.filters.diseaseIds = undefined;
                    }
                }

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
            loadVisits();
        }

        function storeDiseaseDivisionName(value) {
            if(value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function loadUnicorns() {
            if (angular.isUndefined(vm.filters.feasibleByPractitionerId)) {
                vm.filters.feasibleByPractitionerId = vm.account.practitioner.id;
            }
            if(!vm.account.practitioner.healthFacilityId) {
                return;
            }
            Practitioner.query({
                'healthFacilityId.equals': vm.account.practitioner.healthFacilityId,
                size: 1000,
                sort: ['lastName,asc'],
            }).$promise.then((unicorns) => {
                vm.unicorns = unicorns;
                vm.unwatchFeasibleByPractitionerId = $scope.$watch('vm.filters.feasibleByPractitionerId', (newVal, oldVal) => {
                    if(newVal === oldVal) {
                        return;
                    }
                    loadVisits();
                });
            });
        }

        /**
         * Get the user account.
         * @return {*} a promise with the user account.
         */
        function getAccount() {
            return Principal.identity().then(function (account) {
                if (account) {
                    vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

                    if (!account.practitioner) {
                        throw new Error("L'utilisateur n'est pas un professionnel de santé");
                    }
                    vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                    vm.practitioner = account.practitioner;
                    vm.isDoctor = account.practitioner.job === 'J10';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isOtherPractitioner = account.practitioner.job === 'J99';
                }
                return vm.account = account;
            });
        }

        /**
         * Load all visits and filter them to render them on the screen.
         */
        function loadVisits() {
            vm.loadingVisit = true;
            vm.visits = [];
            vm.filteredVisits = null;
            resetCountByStatus();

            if(vm.visitTypes.length) {

                // Hospitalier ou cellule de coordination
                Promise.all([
                    getPlannedVisits(vm.visitTypes),
                    getTodoOrInProgressVisits(vm.visitTypes),
                    getExpiredVisits(vm.visitTypes),
                    getDoneVisits(vm.visitTypes),
                ]).then((results) => {
                    vm.visits = vm.visits.concat(results[0], results[1], results[2], results[3]);
                    vm.filteredVisits = filterVisits(vm.visits, true);
                    vm.countByStatus = countByVisitsStatus(vm.visits);

                }).finally(() => {

                    vm.loadingVisit = false;

                }).catch((error) => {
                    $log.error(error);
                });
            } else {
                vm.filteredVisits = [];
                vm.loadingVisit = false;
            }
        }

        /**
         * Get expired visits for the selected date.
         * @param visitTypes - The list of visit types available for the user.
         * @return {*} a promise with expired visits
         */
        function getExpiredVisits(visitTypes) {
            return Visit.query({
                size: 1000,
                feasibleByPractitionerId: vm.filters.feasibleByPractitionerId,
                "diseaseId.in": getDiseaseIdIn(),
                "hdj.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hdj',
                "hah.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hah',
                "status.in": ['EXPIRED'],
                'type.in': visitTypes,
                'limitDate.lessThanOrEqual': moment(vm.selectedDate.date, 'DD/MM/YYYY').endOf('day').toISOString(),
                'limitDate.greaterThanOrEqual': moment(vm.selectedDate.date, 'DD/MM/YYYY').startOf('day').toISOString(),
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        /**
         * Get to-do or in progress visits for the selected date.
         * @param visitTypes - The list of visit types available for the user.
         * @return {*} a promise with to-do or in progress visits
         */
        function getTodoOrInProgressVisits(visitTypes) {
            return Visit.query({
                size: 1000,
                feasibleByPractitionerId: vm.filters.feasibleByPractitionerId,
                "diseaseId.in": getDiseaseIdIn(),
                "hdj.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hdj',
                "hah.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hah',
                "status.in": ['TODO', 'IN_PROGRESS'],
                'type.in': visitTypes,
                'availableOnDate': moment(vm.selectedDate.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                'plannedDate.specified': false,
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        /**
         * Get planned visits for the selected date.
         * @return {*} a promise with planned visits.
         */
        function getPlannedVisits(visitTypes) {
            return Visit.query({
                size: 100000,
                feasibleByPractitionerId: vm.filters.feasibleByPractitionerId,
                "diseaseId.in": getDiseaseIdIn(),
                "hdj.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hdj',
                "hah.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hah',
                'status.in': ['TODO', 'IN_PROGRESS'],
                'type.in': visitTypes,
                'plannedDate.lessThanOrEqual': moment(vm.selectedDate.date, 'DD/MM/YYYY').endOf('day').toISOString(),
                'availableOnDate': moment(vm.selectedDate.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        /**
         * Load done visits for the selected date.
         * @param visitTypes - The list of visit types available for the user.
         * @return {*} a promise with done visits.
         */
        function getDoneVisits(visitTypes) {
            return Visit.query({
                size: 100000,
                feasibleByPractitionerId: vm.filters.feasibleByPractitionerId,
                "diseaseId.in": getDiseaseIdIn(),
                "hdj.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hdj',
                "hah.equals": vm.filters.therapyType === null ? undefined : vm.filters.therapyType === 'hah',
                'status.in': ['DONE'],
                'type.in': visitTypes,
                'date.lessThanOrEqual': moment(vm.selectedDate.date, 'DD/MM/YYYY').endOf('day').toISOString(),
                'date.greaterThanOrEqual': moment(vm.selectedDate.date, 'DD/MM/YYYY').startOf('day').toISOString(),
                sort: ['orderingDate,asc', 'id,asc'],
            }).$promise;
        }

        function getDiseaseIdIn() {
            if (vm.filters.diseaseIds && vm.filters.diseaseIds.length) {
                return vm.filters.diseaseIds;
            }
            return undefined;
        }

        /**
         * Apply the filters to the list of visits.
         * @param visits - The visits to filter.
         * @param filterStatus - If true, the visits will be filtered by status.
         * @return {*[]}
         */
        function filterVisits(visits, filterStatus) {
            let filteredVisits = [...visits];

            // filtre par statut
            if (filterStatus) {
                filteredVisits = filterVisitsByStatus(filteredVisits, vm.filters.status);
            }

            filteredVisits = filterAdhoc(filteredVisits);

            // filtre par type de visite si une liste de types de visite a été sélectionnée
            filteredVisits = filterVisitByType(filteredVisits, vm.filters.visitType);

            return filteredVisits;
        }

        function filterAdhoc(visits) {
            return visits.filter((visit) => !visit.adhoc || visit.assigned);
        }

        /**
         * Filter the visits by status.
         * @param visits - The visits to filter.
         * @param statutsList - The list of status to filter by.
         * @return *
         */
        function filterVisitsByStatus(visits, statutsList) {
            return visits.filter((visit) => statutsList[visit.status]);
        }

        /**
         * Filter the visits by type.
         * @param visits - The visits to filter.
         * @param visitType - The visit type to filter by.
         * @return {*[]}
         */
        function filterVisitByType(visits, visitType) {
            return visits.filter((visit) => !visitType || visitType === visit.type);
        }

        function resetCountByStatus() {
            vm.countByStatus = {
                EXPIRED: null,
                TODO: null,
                IN_PROGRESS: null,
                DONE: null
            };
        }

        /**
         * Count the number of visits by status.
         * @param visits - The visits to count.
         */
        function countByVisitsStatus(visits) {
            const filteredVisits = filterVisits(visits, false);
            return filteredVisits.reduce((countByStatus, visit) => {
                countByStatus[visit.status] = (countByStatus[visit.status] || 0) + 1;
                return countByStatus;
            }, {
                EXPIRED: 0,
                TODO: 0,
                IN_PROGRESS: 0,
                DONE: 0
            });
        }

        /**
         * Initialise the date planning with the date days of the current week without weekend.
         * @param {moment} monday - The start date of the week.
         */
        function initWeekPlanningDate(monday) {
            vm.planningDates = [];
            const today = moment();
            const firstWeekDay = moment(monday).startOf('week');
            for (let i = 0; i < 7; i++) {
                const date = moment(firstWeekDay).add(i, 'days');
                const isToday = date.format('DD/MM/YYYY') === today.format('DD/MM/YYYY');

                const planningDate = {
                    weekDay:  date.format('dddd'),
                    dayNumber: date.format('D'),
                    month: date.format('MMMM'),
                    date: date.format('DD/MM/YYYY'),
                    isToday
                };

                if (isToday && !vm.selectedDate) {
                    vm.selectedDate = planningDate;
                }

                vm.planningDates.push(planningDate);
            }
        }

        /**
         * Handles clicking a date.
         *
         * This function is called when a date is selected.
         * It takes the selected date as a parameter and assigns it to the vm.selectedDate property.
         *
         * @param {{date: string}} selectedDate - The date that was selected
         */
        function onSelectedDate(selectedDate) {
            if (!vm.loadingVisit) {
                vm.selectedDate = selectedDate;
                loadVisits();
            }
        }

        /**
         * Handles clicking the previous week button.
         */
        function onClickPreviousWeek() {
            if (!vm.loadingVisit) {
                const firstDayCurrentWeek = vm.planningDates[0];
                const previousFirstDayCurrentWeek = moment(firstDayCurrentWeek.date, 'DD/MM/YYYY').subtract(7, 'days');
                initWeekPlanningDate(previousFirstDayCurrentWeek);
            }
        }

        /**
         * Handles clicking the next week button.
         */
        function onClickNextWeek() {
            if (!vm.loadingVisit) {
                const firstDayCurrentWeek = vm.planningDates[0];
                const nextFirstDayCurrentWeek = moment(firstDayCurrentWeek.date, 'DD/MM/YYYY').add(7, 'days');
                initWeekPlanningDate(nextFirstDayCurrentWeek);
            }
        }

        /**
         * Handles clicking the next day button.
         */
        function onClickNextDay() {
            if (!vm.loadingVisit) {
                const FRIDAY = 5;
                let newDateToSelect = null;
                // si la date sélectionnée est un vendredi, on ajoute 3 jours
                if (moment(vm.selectedDate.date, 'DD/MM/YYYY').day() === FRIDAY) {
                    newDateToSelect = moment(vm.selectedDate.date, 'DD/MM/YYYY').add(3, 'days').format('DD/MM/YYYY');
                } else {
                    newDateToSelect = moment(vm.selectedDate.date, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
                }

                initWeekPlanningDate(moment(newDateToSelect, 'DD/MM/YYYY').startOf('week'));
                vm.selectedDate = vm.planningDates.find((planningDate) => planningDate.date === newDateToSelect);

                loadVisits();
            }
        }

        /**
         * Handles clicking the previous day button.
         */
        function onClickPreviousDay() {
            if (!vm.loadingVisit) {
                const newDateToSelect = moment(vm.selectedDate.date, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY');
                initWeekPlanningDate(moment(newDateToSelect, 'DD/MM/YYYY').startOf('week'));
                vm.selectedDate = vm.planningDates.find((planningDate) => planningDate.date === newDateToSelect);

                // Si on ne trouve pas la date dans le calendrier, on prend le dernier jour dans le planning qui correspond au vendredi
                if (!vm.selectedDate) {
                    vm.selectedDate = vm.planningDates[vm.planningDates.length - 1];
                }

                loadVisits();
            }
        }

        function onChangeFilter(newVal, oldVal) {
            if(angular.equals(newVal, oldVal)) {
                return;
            }
            vm.filteredVisits = filterVisits(vm.visits, true);
            vm.countByStatus = countByVisitsStatus(vm.visits);
        }

        /**
         * Open the plan dialog for a visit.
         * @param visit - the visit to plan.
         */
        function plan(visit) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then(() => {
                loadVisits();
            }, () => {
                // do nothing
            });
        }

        /**
         * Handle the click on PDF done visit button.
         * @param visit - the visit the user want download the PDF.
         */
        function exportPdf(visit) {
            if (vm.isExportingPdf)
                return;
            vm.isExportingPdf = true;
            Visit.exportPdf({
                id: visit.id
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function () {
                    vm.isExportingPdf = false;
                });
        }

        function getVisitTypeForUser() {
            let role;
            if (vm.isCoordination) {
                role = 'COORDINATION_FACILITY';
            } else if (vm.isDoctor && vm.isHospitalUser) {
                role = 'FACILITY_DOCTOR';
            } else if (vm.isNurse && vm.isHospitalUser) {
                role = 'NURSE_COORD';
            } else if (vm.isPharmacist && vm.isHospitalUser) {
                role = 'FACILITY_PHARMACIST';
            } else {
                role = null;
            }
            return Visit.getVisitTypeForUser(role);
        }

    }
})();
