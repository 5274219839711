(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDetailController', PatientTherapyDetailController);

    PatientTherapyDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', '$interval', '$filter', '$uibModal', '$window', 'entity', 'PatientTherapy', 'Patient', 'Practitioner', 'HealthFacility', 'Therapy', 'Visit', 'moment', 'Principal', 'MedicationVariant', 'PatientTreatment', 'PharmacyOrder', 'CareTeam'];

    function PatientTherapyDetailController($scope, $rootScope, $state, $stateParams, $timeout, $interval, $filter, $uibModal, $window, entity, PatientTherapy, Patient, Practitioner, HealthFacility, Therapy, Visit, moment, Principal, MedicationVariant, PatientTreatment, PharmacyOrder, CareTeam) {
        const vm = this;

        vm.patientTherapy = entity;

        // load basic patient data - will be overridden with full data
        vm.patient = vm.patientTherapy.patient;

        $window.document.title = entity.programName + ' #' + entity.id;

        vm.addVisit = addVisit;
        vm.planSingleRenewed = planSingleRenewed;
        vm.selectPharmacy = selectPharmacy;
        vm.clearPharmacy = clearPharmacy;
        vm.openPharmacy = openPharmacy;
        vm.openPharmacyEmailDialog = openPharmacyEmailDialog;
        vm.invitePharmacist = invitePharmacist;
        vm.start = start;
        vm.reassign = reassign;
        vm.cancel = cancel;
        vm.edit = edit;
        vm.shift = shift;
        vm.pause = pause;
        vm.resume = resume;
        vm.stop = stop;
        vm.deletePatientTherapy = deletePatientTherapy;
        vm.inviteMainDoctor = inviteMainDoctor;
        vm.clearMainDoctor = clearMainDoctor;
        vm.addMedication = addMedication;
        vm.openMedication = openMedication;

        vm.$onInit = function () {
            loadPatient();
            loadTherapyAndVisits();
            loadTeam();
        };

        Principal.identity().then(function (account) {
            vm.isApprobator = account.practitioner.job === 'J10' && vm.patientTherapy.doctor.id === account.practitioner.id;
            if (account.authorities.includes('ROLE_ADMIN')) {
                loadPharmacyOrders();
            }
        });

        function loadPatient() {
            Patient.get({id: vm.patientTherapy.patient.id}, patient =>
                vm.patient = patient);
        }

        function loadTherapyAndVisits() {
            Promise.all([loadTherapy(), loadVisits()]).then(() => {

                if (['S20_ACTIVE', 'S30_PAUSED'].includes(vm.patientTherapy.status)
                    && vm.patientTherapy.singleRenewed) {
                    // chargement du questionnaire renouvelable
                    vm.singleRenewedVisit = vm.visits.find(v =>
                        v.frequency
                        && v.renewalAtExpiration
                        && ['TODO', 'IN_PROGRESS', 'PAUSED'].includes(v.status));
                } else if (vm.patientTherapy.status === 'S11_VALIDATED'
                    && vm.therapy
                    && vm.therapy.singleRenewed) {
                    // chargement du modèle de questionnaire renouvelable
                    vm.singleRenewedVisit = vm.therapy.plan.visits[0];
                    if (vm.singleRenewedVisit && vm.patientTherapy.singleOverride) {
                        if (vm.patientTherapy.singleOverride.frequency) {
                            vm.singleRenewedVisit.frequency = vm.patientTherapy.singleOverride.frequency;
                        }
                        if (vm.patientTherapy.singleOverride.reminderDay !== null) {
                            vm.singleRenewedVisit.reminderDay = vm.patientTherapy.singleOverride.reminderDay;
                        }
                        if (vm.patientTherapy.singleOverride.limitDays !== null) {
                            vm.singleRenewedVisit.limitDays = vm.patientTherapy.singleOverride.limitDays;
                        }
                    }
                } else {
                    // désactivation du paramétrage de questionnaire renouvelable
                    vm.singleRenewedVisit = null;
                }
                loadAgenda();
            });
        }

        function loadAgenda() {
            // exclude single renewed from agenda
            vm.agenda = vm.visits.filter(v =>
                !vm.singleRenewedVisit
                || !vm.singleRenewedVisit.id
                || v.id !== vm.singleRenewedVisit.id);
        }

        function loadTherapy() {
            if (!vm.patientTherapy.therapyId) {
                return Promise.resolve(null);
            }
            return Therapy.get({ id: vm.patientTherapy.therapyId }).$promise.then(therapy => {
                vm.therapy = therapy;
            });
        }

        function loadVisits() {
            return Visit.query({
                "patientTherapyId.equals": vm.patientTherapy.id,
                sort: ['orderingDate', 'id'],
                size: 1000 // TODO hard-coded size
            }).$promise.then(visits => {
                const today = moment();
                if(['S20_ACTIVE', 'S30_PAUSED'].includes(vm.patientTherapy.status)) {
                    vm.visits = visits.map(v => {
                        const diff = today.diff(v.orderingDate, 'days');
                        v.hiddenPast = diff > 15;
                        v.hiddenFuture = diff < -15;
                        return v;
                    });
                } else {
                    vm.visits = visits;
                }
                vm.countVisitsDone = $filter('filter')(visits, {status: 'DONE'}).length;
                return visits;
            });
        }

        function addVisit(type, pricing) {

            switch (type) {
            case 'GUIDANCE' :
                createGuidanceVisit().then(planVisit);
                break;

            default :
                $state.go('patient-therapy-detail.add', {type: type, pricing: pricing});
            }

        }

        function createGuidanceVisit() {
            return Visit.createOwn({
                patientTherapyId: vm.patientTherapy.id,
                type: 'GUIDANCE',
                steps: ['end'],
                adhoc: false,
            }).$promise.then(data => data.id);
        }

        function planVisit(id) {
            return $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => Visit.get({ id }).$promise,
                }
            }).result.then(() => {
                loadVisits().then(() => {
                    loadAgenda();
                });
            }, () => {
                // do nothing
            });
        }

        function planSingleRenewed() {
            planVisit(vm.singleRenewedVisit.id).then(() =>
                Visit.get({ id: vm.singleRenewedVisit.id }).$promise.then(visit =>
                    vm.singleRenewedVisit = visit));
        }

        function loadTeam() {
            vm.pharmacy = null;
            vm.mainDoctor = null;
            if (['picto', 'akoapharma', 'oncolink'].includes(vm.patientTherapy.programCode)) {
                CareTeam.getMembers(vm.patientTherapy.patientId).then(response => {
                    const pharmacyMember = response.data.find(m => m.role === 'PHARMACY');

                    vm.pharmacy = pharmacyMember ? pharmacyMember.entity : null;

                    if (vm.pharmacy) {
                        Practitioner.query({
                            'job.equals': 'J21',
                            'healthFacilityId.equals': vm.pharmacy.id
                        }, pharmacists => {
                            vm.pharmacy.pharmacists = pharmacists;
                        });
                    }

                    const mainDoctorTeamMember = response.data.find(m => m.role === 'MAIN_DOCTOR');
                    vm.mainDoctor = mainDoctorTeamMember ? mainDoctorTeamMember.entity : null;
                });
            }
        }

        function loadPharmacyOrders() {
            PharmacyOrder.query({
                "patientTherapyId.equals": vm.patientTherapy.id
            }, results => vm.pharmacyOrders = results);
        }

        function selectPharmacy() {
            openFacilitySelection('PHARMACY').result.then(updatePharmacy);
        }

        function clearPharmacy() {
            CareTeam.removeMember(vm.patientTherapy.patientId, 'PHARMACY', vm.pharmacy.id).then(loadTeam);
        }

        function updatePharmacy(id) {
            CareTeam.addMember(vm.patientTherapy.patientId, 'PHARMACY', id).then(loadTeam);
        }

        function openPharmacy() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-modal.html',
                controller: 'HealthFacilityModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    healthFacility: [function () {
                        return vm.pharmacy;
                    }]
                }
            });
        }

        function openPharmacyEmailDialog() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-email-dialog.html',
                controller: 'HealthFacilityEmailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    healthFacility: () => {
                        return HealthFacility.get({id: vm.pharmacy.id, view: 'summary'}).$promise;
                    }
                }
            }).result.then(email => {
                vm.pharmacy.email = email;
            });
        }

        function openFacilitySelection(healthFacilityType) {
            return $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-selection.html',
                controller: 'HealthFacilitySelectionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pagingParams: [function () {
                        return {
                            predicate: 'name',
                            ascending: true
                        };
                    }],
                    search: [function () {
                        return {
                            name: null,
                            address: null,
                            postalCode: vm.patientTherapy.patient.postalCode,
                            city: vm.patientTherapy.patient.city
                        };
                    }],
                    healthFacilityType: [function () {
                        return healthFacilityType;
                    }]
                }
            });
        }

        function invitePharmacist() {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        healthFacilityId: vm.pharmacy.id,
                        job: 'J21',
                        firstName: null,
                        lastName: null,
                        rpps: null,
                        legalStatus: null,
                        legalSubStatus: null,
                        legalRegistrationNumber: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        emailShown: false,
                        mobilePhoneShown: false,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addHealthFacility'
                    }
                }
            }).result.then(() => {
                loadTeam();
            });
        }

        vm.getNumber = function (num) {
            return new Array(num);
        };

        function start() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-start.html',
                controller: 'PatientTherapyStartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        return $translate.refresh();
                    }]
                }
            }).result.then(() => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function inviteMainDoctor() {
            openPractitionerInvitation('J10').result.then( (doctorId) => {
                CareTeam.addMember(vm.patientTherapy.patientId, 'MAIN_DOCTOR', doctorId).then(loadTeam);
            });
        }

        function clearMainDoctor() {
            CareTeam.removeMember(vm.patientTherapy.patientId, 'MAIN_DOCTOR', vm.mainDoctor.id).then(loadTeam);
        }

        function openPractitionerInvitation(job) {
            const patient = vm.patientTherapy.patient;

            return $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        job: job,
                        speciality: null,
                        category: null,
                        firstName: null,
                        lastName: null,
                        adeli: null,
                        rpps: null,
                        siret: null,
                        iban: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        latitude: null,
                        longitude: null,
                        cityCode: null,
                        country: 'FR',
                        legalRegistrationNumber: null,
                        legalStatus: null,
                        legalSubStatus: null,
                        notes: null,
                        userAuthType: null,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addTeam',
                        searchCriteria: {
                            address: patient.address,
                            postalCode: patient.postalCode,
                            city: patient.city,
                            latitude: patient.latitude,
                            longitude: patient.longitude,
                            legalStatus: null
                        }
                    }
                }
            });
        }

        function reassign() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-reassign.html',
                controller: 'PatientTherapyReassignController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then(() => {
                PatientTherapy.get({id: vm.patientTherapy.id}, patientTherapy => vm.patientTherapy = patientTherapy);
                loadVisits().then(() => {
                    loadAgenda();
                });
            }, () => {
                // nothing
            });
        }

        function cancel() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-cancel.html',
                controller: 'PatientTherapyCancelController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then((result) => {
                PatientTherapy.get({id: vm.patientTherapy.id}, patientTherapy => vm.patientTherapy = patientTherapy);
                loadVisits().then(() => {
                    loadAgenda();
                });
            }, () => {
                // nothing
            });
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-dialog.html',
                controller: 'PatientTherapyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['PatientTherapy', (PatientTherapy) => PatientTherapy.get({id: $stateParams.id}).$promise]
                }
            }).result.then(() => {
                PatientTherapy.get({ id: vm.patientTherapy.id }, patientTherapy => {
                    vm.patientTherapy = patientTherapy;
                    loadTherapyAndVisits();
                });
            }, () => {
                // nothing
            });
        }

        function deletePatientTherapy() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-delete-dialog.html',
                controller: 'PatientTherapyDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['PatientTherapy', function (PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            }).result.then((result) => {
                $state.go('patient-detail.therapies', {id: vm.patientTherapy.patientId});
            }, () => {
                // nothing
            });
        }

        function shift() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'SHIFT_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function pause() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'PAUSE_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function resume() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'RESUME_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function stop() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-management.html',
                controller: 'PatientTherapyManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    action: () => 'STOP_THERAPY',
                    patientTherapy: () => PatientTherapy.get({id: vm.patientTherapy.id}).$promise,
                }
            }).result.then(result => {
                $state.reload();
            }, () => {
                // do nothing
            });
        }

        function addMedication() {
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-medication-add-dialog.html',
                controller: 'PatientTherapyMedicationAddDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    options: () => ({
                        patientId: vm.patient.id,
                        search: true,
                        restrict: false,
                        diseaseId: vm.patientTherapy.diseaseId,
                        exclusions: vm.patientTherapy.medications.map(medication => medication.id)
                    })
                }
            }).result.then(medication => {
                PatientTherapy.addMedication({id: vm.patientTherapy.id}, medication.id).$promise.then(() =>
                    vm.patientTherapy.medications.push(medication));
            }, () => {
                // nothing
            });
        }

        function openMedication(medication) {
            $uibModal.open({
                templateUrl: 'app/entities/medication/medication-preview-modal.html',
                controller: 'MedicationPreviewModalController',
                controllerAs: 'vm',
                size: vm.patient.id ? 'lg' : 'md',
                resolve: {
                    medication: () => medication,
                    patientId: () => vm.patient.id,
                    options: {}
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });

        }

    }
})();
