(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('ActuatorLoggers', ActuatorLoggers);

    ActuatorLoggers.$inject = ['$http'];

    function ActuatorLoggers($http) {

        return { list, update };

        function list() {
            return $http.get('management/loggers').then(response => response.data);
        }

        function update(name, configuredLevel) {
            return $http.post('management/loggers/' + name, { configuredLevel });
        }
    }
})();
