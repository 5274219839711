(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDialogController', PatientTherapyDialogController);

    PatientTherapyDialogController.$inject = ['$log', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'entity', 'PatientTherapy', 'Therapy', 'Principal', 'ContractLine', 'Visit'];

    function PatientTherapyDialogController($log, $timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, entity, PatientTherapy, Therapy, Principal, ContractLine, Visit) {

        const vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.patientTherapy = entity;
        vm.form = {
            notes: entity.notes,
            treatmentDay1Date: entity.treatmentDay1Date,
            assigneeId: entity.assignee && entity.assignee.id,
            patientStatus: entity.patientStatus,
            singleOverride: entity.singleOverride
        };

        vm.visitFrequencies = Visit.getFrequencies();

        loadTherapyContractLines();

        PatientTherapy.findAvailableCoordinators(vm.patientTherapy).then(coordinators => {
            vm.coordinators = coordinators;
        });

        Principal.identity().then(function (account) {
            vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.$onInit = () => {
            if (vm.patientTherapy.status === 'S11_VALIDATED') {
                vm.form.therapyId = entity.therapyId;
                vm.unWatchTherapyId = $scope.$watch("vm.form.therapyId", onTherapyIdChange);
                vm.unWatchSingleOverride = $scope.$watch('vm.form.singleOverride', onSingleOverrideChange, true);
            } else {
                loadTherapy(entity.therapyId);
            }
        };

        vm.$onDestroy = () => {
            if (vm.unWatchTherapyId) {
                vm.unWatchTherapyId();
            }
            if (vm.unWatchSingleOverride) {
                vm.unWatchSingleOverride();
            }
        };

        function onTherapyIdChange(newVal, oldVal) {
            if (newVal) {
                loadTherapy(newVal);
            } else {
                vm.therapy = null;
                vm.form.singleOverride = null;
            }
        }

        function onSingleOverrideChange(newVal, oldVal) {
            if (newVal && newVal.limitDays < newVal.reminderDay) {
                vm.form.singleOverride.reminderDay = newVal.limitDays - 1;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            PatientTherapy.update({id: vm.patientTherapy.id}, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate');
            $scope.$emit('continuumplatformApp:internalNotesUpdate');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadTherapyContractLines() {
            PatientTherapy.getTherapyContractLines({
                id: vm.patientTherapy.id,
                date: moment(vm.patientTherapy.startDate || new Date()).format('YYYY-MM-DD')
            }, function (therapies) {
                vm.therapyContractLines = therapies
                    .map(prefixTherapy)
                    .sort((tcl1, tcl2) => tcl1.therapyName.localeCompare(tcl2.therapyName));

                // add existing
                if (vm.patientTherapy.therapyId
                    && !vm.therapyContractLines
                        .filter(function (tcl) {
                            return tcl.therapyId === vm.patientTherapy.therapyId;
                        }).length) {

                    if (vm.patientTherapy.contractLineId) {
                        ContractLine.get({id: vm.patientTherapy.contractLineId}, function (cl) {
                            vm.therapyContractLines.push({
                                therapyId: vm.patientTherapy.therapyId,
                                therapyName: vm.patientTherapy.therapyName,
                                lineId: cl.id,
                                customerName: cl.customerName,
                                healthFacilityName: cl.healthFacilityName,
                                contractComment: cl.contractComment,
                                lineComment: cl.lineComment,
                                contractQuota: cl.contractQuota,
                                contractConsumption: cl.contractConsumption,
                            });
                        });
                    } else {
                        vm.therapyContractLines.push({
                            therapyId: vm.patientTherapy.therapyId,
                            therapyName: vm.patientTherapy.therapyName,
                        });
                    }

                }
            });
        }

        function prefixTherapy(tcl) {
            if (tcl.therapyHah)
                tcl.prefix = 'HAD / ';
            else if (tcl.therapyHdj)
                tcl.prefix = 'HDJ / ';
            else
                tcl.prefix = null;
            return tcl;
        }

        function loadTherapy(id) {
            Therapy.get({id}, therapy => {
                vm.therapy = therapy;

                if (['S20_ACTIVE', 'S30_PAUSED'].includes(vm.patientTherapy.status)
                    && vm.patientTherapy.singleRenewed) {
                    // chargement du questionnaire renouvelable
                    Visit.query({
                        'patientTherapyId.equals': vm.patientTherapy.id,
                        'frequency.specified': true,
                        'renewalAtExpiration.equals': true,
                        'status.in': ['TODO', 'IN_PROGRESS', 'PAUSED'],
                    }).$promise.then(results => {
                        if (results.length !== 1) {
                            $log.error(`Found ${results.length} renewable visits, expected 1`);
                            return;
                        }
                        setFormSingleOverride(results[0]);
                    });
                } else if (vm.therapy && vm.therapy.singleRenewed) {
                    // chargement du modèle de questionnaire renouvelable
                    const singleVisit = vm.therapy.plan.visits[0];
                    setFormSingleOverride(singleVisit);
                    override(vm.patientTherapy.singleOverride);
                } else {
                    // désactivation du paramétrage de questionnaire renouvelable
                    vm.form.singleOverride = null;
                }
            });
        }

        function setFormSingleOverride(v) {
            vm.form.singleOverride = {
                frequency: v.frequency,
                reminderDay: v.reminderDay,
                limitDays: v.limitDays,
            };
        }

        function override(singleOverride) {
            if (!singleOverride) {
                return;
            }
            if (singleOverride.frequency) {
                vm.form.singleOverride.frequency = singleOverride.frequency;
            }
            if (singleOverride.reminderDay !== null) {
                vm.form.singleOverride.reminderDay = singleOverride.reminderDay;
            }
            if (singleOverride.limitDays !== null) {
                vm.form.singleOverride.limitDays = singleOverride.limitDays;
            }
        }

    }
})();
