(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyRequestController', PatientTherapyRequestController);

    PatientTherapyRequestController.$inject = ['$rootScope', '$state', '$timeout', '$scope', '$filter', '$uibModal', '$log', '$window', 'moment', 'entity', 'Patient', 'PatientTherapy', 'Practitioner', 'Principal', 'Upload', 'Disease', 'ContractLine', 'ContractualTreatmentGroup', 'CompressImage', 'MeasureType', 'CareTeam', 'Media', 'HealthFacility', 'Pecam'];

    function PatientTherapyRequestController($rootScope, $state, $timeout, $scope, $filter, $uibModal, $log, $window, moment, entity, Patient, PatientTherapy, Practitioner, Principal, Upload, Disease, ContractLine, ContractualTreatmentGroup, CompressImage, MeasureType, CareTeam, Media, HealthFacility, Pecam) {
        const vm = this;

        vm.cancel = cancel;
        vm.save = save;
        vm.onSelectDisease = onSelectDisease;
        vm.backToPatientEdit = backToPatientEdit;
        vm.addMedication = addMedication;
        vm.removeMedicationAtIndex = removeMedicationAtIndex;
        vm.onSelectedDiseaseType = onSelectedDiseaseType;
        vm.compressUploadPrescription = compressUploadPrescription;
        vm.showPrescriptionMedia = showPrescriptionMedia;
        vm.deletePrescriptionFile = deletePrescriptionFile;

        vm.patient = entity;

        vm.selectedDisease = null;
        vm.selectedProgram = null;
        vm.selectedTherapy = null;
        vm.requireTherapySelection = null;
        vm.requireTreatmentDay1Date = null;
        vm.requesterId = null;
        vm.suggestPharmacyOrder = null;
        vm.pharmacyOrder = null;
        vm.showMedicationPrescription = null;
        vm.requireMedicationPrescription = null;
        vm.prescriptions = {
            medication: initPrescription(),
            telemonitoring: initPrescription(),
        };
        vm.telemonitoringPrescriptionGenerationAvailable = null;
        vm.hasValidTelemonitoringPrescription = null;
        vm.pecamEnabled = null;
        vm.pecamAvailable = null;
        vm.pecam = null;
        vm.createPatientUser = false;
        vm.diseaseType = null;
        vm.patientLess18YearOld = false;

        vm.team = {
            facilityDoctorId: null,
            facilityPharmacistId: null,
            nurseCoordId: null,
        };

        vm.measures = {
            weight: null,
            size: null,
            pressure1: null,
            pressure2: null,
            gfr: null
        };

        vm.isConsentRequired = false;
        vm.agreement1 = false;
        vm.agreement2 = false;
        vm.agreement3 = false;

        vm.healthfacilities = [];
        vm.practitioners = [];
        vm.prescriptionFile = null;

        vm.isEditingDisease = true;

        vm.addTreatmentShown = false;
        vm.showTreatmentsBlock = false;

        vm.treatmentDay1Date = null;
        vm.minTelemonitoringPrescriptionDateIso = moment().subtract(6, 'months').format('YYYY-MM-DD');
        vm.todayIso = moment().format('YYYY-MM-DD');

        vm.measureRules = MeasureType.getRules();

        let needsRefreshPrograms = false;

        vm.medications = [];

        // prevent user from exiting
        $rootScope.warnBeforeUnload = true;
        vm.isCancelDialogOpened = false;
        vm.uiCanExit = transition => {
            if (vm.isCancelDialogOpened) {
                history.pushState(null, $window.document.title, location.href);
                return false;
            }

            if (['patient-detail.edit-identity', 'patient-detail.edit-address', 'patient-detail.edit-user'].includes(transition.to().name)) {
                return true;
            }

            if ($rootScope.warnBeforeUnload) {
                vm.cancel(transition);
                history.pushState(null, $window.document.title, location.href);
                return false;
            }
            vm.isCancelDialogOpened = false;
        };

        loadPatientHealthFacility();

        loadContractualTreatmentGroups();

        // load health pro
        Principal.identity().then(account => {

            if (account.authorities.includes('ROLE_ADMIN')) {

                vm.isAdmin = true;

            } else {

                const practitioner = account.practitioner;
                vm.practitioner = practitioner;

                if (practitioner.job === 'J10') {
                    vm.isDoctor = true;
                } else if (practitioner.job === 'J60') {
                    vm.isNurse = true;
                } else if (practitioner.job === 'J21') {
                    vm.isPharmacist = true;
                }

                setCurrentTeam().then(() => {

                    if (vm.isNurse && practitioner.healthFacilityType === 'HOSPITAL') {
                        vm.team.nurseCoordId = practitioner.id;
                    } else if (vm.isPharmacist && practitioner.healthFacilityType === 'HOSPITAL') {
                        vm.team.facilityPharmacistId = practitioner.id;
                    } else if (vm.isDoctor) {
                        vm.prescriptions.telemonitoring.prescriberId = practitioner.id;
                        vm.prescriptions.medication.prescriberId = practitioner.id;
                        vm.team.facilityDoctorId = practitioner.id;
                    }

                    $scope.$watch('vm.prescriptions.telemonitoring.prescriberId', (newVal, oldVal) => {
                        if (newVal) {
                            if (vm.team.facilityDoctorId === null) {
                                vm.team.facilityDoctorId = newVal;
                            }
                            if (vm.prescriptions.medication.prescriberId === null) {
                                vm.prescriptions.medication.prescriberId = newVal;
                            }
                            if (vm.hasValidTelemonitoringPrescription === false) {
                                if (vm.telemonitoringPrescriptionGenerationAvailable) {
                                    vm.prescriptions.telemonitoring.mode = newVal === practitioner.id ? 'GENERATE' : 'REQUEST';
                                } else if (vm.telemonitoringPrescriptionGenerationAvailable === false) {
                                    vm.prescriptions.telemonitoring.mode = 'UPLOAD';
                                }
                            }
                        }
                    });

                    $scope.$watch('vm.prescriptions.medication.prescriberId', (newVal, oldVal) => {
                        if (newVal) {
                            if (vm.team.facilityDoctorId === null) {
                                vm.team.facilityDoctorId = newVal;
                            }
                        }
                    });
                });
            }

            loadPractitioners(vm.patient.healthFacilityId);
            loadContractLines(vm.patient.healthFacilityId);
        });

        $scope.$watch('vm.selectedDisease', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                vm.metastatic = null;
                vm.selectedProgram = null;
                if (newValue && vm.contractLines) {
                    refreshPrograms();
                }
            }
        });

        $scope.$watch('vm.selectedProgram', (newVal, oldVal) => {
            if (newVal !== oldVal && vm.contractLines) {
                refreshSponsors();
                refreshTherapies();
                refreshArticle51();
                refreshMedicationPrescription();
                refreshPecam();
                vm.createPatientUser = !vm.patient.userId && vm.selectedProgram.pro;
                vm.isConsentRequired = newVal && newVal.continuum && !newVal.trial;
            }
        });

        $scope.$watch('vm.prescriptions.medication.mode', (newVal, oldVal) => {
            if (oldVal === 'UPLOAD' && vm.prescriptions.medication.media) {
                deletePrescriptionFile('medication');
            }
        });

        $scope.$watch('vm.prescriptions.telemonitoring.mode', (newVal, oldVal) => {
            if (oldVal === 'UPLOAD' && vm.prescriptions.telemonitoring.media) {
                deletePrescriptionFile('telemonitoring');
            }
        });

        $scope.$watch('vm.pecam', (newVal, oldVal) => {
            if (newVal) {
                if (vm.hasValidTelemonitoringPrescription) {
                    vm.prescriptions.telemonitoring.mode = null;
                } else if (vm.hasValidTelemonitoringPrescription === false) {
                    if (vm.telemonitoringPrescriptionGenerationAvailable) {
                        vm.prescriptions.telemonitoring.mode = vm.isDoctor ? 'GENERATE' : 'REQUEST';
                    } else if (vm.telemonitoringPrescriptionGenerationAvailable === false) {
                        vm.prescriptions.telemonitoring.mode = 'UPLOAD';
                    }
                }
            } else {
                vm.prescriptions.telemonitoring = initPrescription();
                deletePrescriptionFile('telemonitoring');
            }
        });

        function setCurrentTeam() {
            return CareTeam.getMembers(vm.patient.id).then(response => {
                response.data.forEach(member => {
                    if (!vm.isNurse && member.role === 'NURSE_COORD') {
                        vm.team.nurseCoordId = member.entity.id;
                    } else if (!vm.isPharmacist && member.role === 'FACILITY_PHARMACIST') {
                        vm.team.facilityPharmacistId = member.entity.id;
                    } else if (!vm.isDoctor && member.role === 'FACILITY_DOCTOR') {
                        vm.prescriptions.telemonitoring.prescriberId = member.entity.id;
                        vm.prescriptions.medication.prescriberId = member.entity.id;
                        vm.team.facilityDoctorId = member.entity.id;
                    }
                });
            });
        }

        function onSelectedDiseaseType() {
            vm.selectedDisease = null;
        }

        function onSelectDisease() {
            vm.allowAllTreatments = vm.contractLines.some(isContractLineForAllTreatments);
            vm.isEditingDisease = false;
            if (!vm.showTreatmentsBlock) {
                vm.addMedication();
            }
        }

        function isContractLineForAllTreatments(contractLine) {
            if (!contractLine.treatmentGroupId)
                return true;

            return contractLine.diseaseId === vm.selectedDisease.id
                && vm.contractualTreatmentGroups[contractLine.treatmentGroupId].length === 0;
        }

        function backToPatientEdit() {
            vm.isEditingDisease = true;
        }

        function cancel(transition) {
            $rootScope.warnBeforeUnload = false;

            deletePrescriptionFile('medication');
            deletePrescriptionFile('telemonitoring');

            if (vm.patient.deletable) {
                vm.isCancelDialogOpened = true;
                $uibModal.open({
                    templateUrl: 'app/activity/patient/patient-therapy-cancel-request.modal.html',
                    controller: 'PatientTherapyCancelRequestModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        currentPatient: () => vm.patient,
                    }

                }).result.then(result => {
                    vm.isCancelDialogOpened = false;
                    if (result === 'saveDraft') {
                        if (transition) {
                            $state.go(transition.to().name);
                        } else {
                            $state.go('patient-detail.therapies', {id: vm.patient.id});
                        }
                    } else if (result === 'deleteDraft') {
                        vm.isDeleting = true;
                        Patient.delete({id: vm.patient.id}, () => {
                            // do nothing
                        }, () => vm.isDeleting = false);
                        if (transition) {
                            $state.go(transition.to().name);
                        } else {
                            $state.go('home');
                        }
                    }

                }, () => {
                    $rootScope.warnBeforeUnload = true;
                    vm.isCancelDialogOpened = false;
                });

            } else if (confirm("Êtes-vous sûr(e) ?")) {
                if (transition) {
                    if (['patient-detail.edit-identity', 'patient-detail.edit-address', 'patient-detail.edit-user'].includes(transition.to().name)) {
                        $state.go(transition.to().name, {id: vm.patient.id});
                    } else {
                        $state.go(transition.to().name);
                    }
                } else {
                    $state.go('patient-detail.therapies', {id: vm.patient.id});
                }
            }
        }

        function save() {
            vm.isSaving = true;
            $rootScope.warnBeforeUnload = false;

            PatientTherapy.save({
                patientId: vm.patient.id,
                patientIdentityUpdate: vm.patient.readonlyIdentity
                    ? null
                    : {
                        birthDate: vm.patient.birthDate,
                        gender: vm.patient.gender,
                        internalId: vm.patient.internalId,
                        ssn: vm.patient.ssn
                    },
                patientEmail: vm.patient.email,
                patientPhone: vm.patient.phone,
                patientMobilePhone: vm.patient.mobilePhone,
                requesterId: vm.requesterId,
                doctorId: vm.team.facilityDoctorId,
                nurseCoordId: vm.team.nurseCoordId,
                facilityPharmacistId: vm.team.facilityPharmacistId,
                programId: vm.selectedProgram.id,
                therapyId: vm.selectedTherapy ? vm.selectedTherapy.id : null,
                trial: vm.selectedProgram.trial,
                trialId: vm.trialId,
                notes: vm.therapyNotes,
                treatmentDay1Date: vm.treatmentDay1Date,
                medicationIds: vm.medications.map(m => m.id),
                medicationPrescriptionMode: vm.prescriptions.medication.mode,
                medicationPrescriptionMediaId: vm.prescriptions.medication.media ? vm.prescriptions.medication.media.id : null,
                medicationPrescriberId: vm.prescriptions.medication.prescriberId,
                medicationPrescriptionDate: vm.prescriptions.medication.date,
                pharmacyOrder: vm.pharmacyOrder,
                measures: vm.measures,
                diseaseId: vm.selectedDisease.id,
                metastatic: vm.metastatic,
                createPatientUser: vm.createPatientUser,
                patientUserCanSendMessage: vm.patientUserCanSendMessage,
                pecam: vm.pecam,
                telemonitoringPrescriptionMode: vm.pecam ? vm.prescriptions.telemonitoring.mode : null,
                telemonitoringPrescriptionMediaId: vm.prescriptions.telemonitoring.media ? vm.prescriptions.telemonitoring.media.id : null,
                telemonitoringPrescriberId: vm.prescriptions.telemonitoring.prescriberId,
                telemonitoringPrescriptionDate: vm.prescriptions.telemonitoring.date,
                createPharmaceuticalConsultation: vm.selectedProgram.code !== 'hemavie' && vm.selectedDisease.cim11Code !== '4A00.14',
                createAnnouncementConsultation: vm.selectedProgram.code !== 'hemavie' && vm.selectedDisease.cim11Code !== '4A00.14'
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go('patient-therapy-detail', {id: result.patientTherapyId});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadPractitioners(healthFacilityId) {
            Practitioner.query({
                "healthFacilityId.equals": healthFacilityId || undefined,
                "healthFacilityType.equals": healthFacilityId ? undefined : "HOSPITAL",
                sort: "lastName",
                size: 1000 // TODO search
            }, practitioners => {
                vm.practitioners = practitioners;
            });
        }

        function loadContractLines(healthFacilityId) {
            $log.debug("Loading contract lines...");
            ContractLine.query({
                size: 999,
                activeInFacilityId: vm.isAdmin && healthFacilityId ? healthFacilityId : undefined,
                activeInAllFacilities: vm.isAdmin && !healthFacilityId ? true : undefined,
                "activeOn": moment().format('YYYY-MM-DD')
            }, onContractLinesLoaded);
        }

        function onContractLinesLoaded(result) {
            $log.debug("Loaded contract lines.");
            vm.contractLines = result;

            vm.allowAllDiseases = false;
            angular.forEach(result, c => {
                if (!c.diseaseId) {
                    vm.allowAllDiseases = true;
                }
            });

            if (vm.allowAllDiseases) {
                Disease.query({
                    "deleted.equals": false,
                    size: 999
                }).$promise.then((diseases) => {
                    vm.diseases = diseases;
                    generateDiseaseTypeList();
                });
            } else {
                vm.diseases = $filter('unique')(result, 'diseaseId')
                    .reduce((total, currentValue, currentIndex, arr) => {
                        if (currentValue.diseaseId)
                            total.push({
                                id: currentValue.diseaseId,
                                name: currentValue.diseaseName,
                                type: currentValue.diseaseType,
                                cim11Code: currentValue.diseaseCim11Code
                            });
                        return total;
                    }, []);
                generateDiseaseTypeList();
            }

            if (needsRefreshPrograms) {
                needsRefreshPrograms = false;
                refreshPrograms();
            }

        }

        function generateDiseaseTypeList() {
            const distinctDiseaseTypes = new Set(vm.diseases
                .map((d) => d['type']));
            vm.diseaseTypeList = [...distinctDiseaseTypes]
                .sort()
                .map((t) => t.split(".")[1]); // suppression des préfixes
            if(vm.diseaseTypeList.length === 1) {
                vm.diseaseType = vm.diseaseTypeList[0];// focus on disease field
                $timeout(() => {
                    angular.element('#field_disease').focus();
                });
            } else {
                $timeout(() => {
                    angular.element('[name=diseaseType]:first').focus();
                });
            }
        }

        function refreshArticle51() {
            if(vm.selectedProgram)
                vm.article51 = vm.contractLines.filter(cl => cl.programId === vm.selectedProgram.id && cl.article51).length > 0;
            else
                vm.article51 = null;
        }

        function loadPatientHealthFacility() {
            HealthFacility.get({
                view: 'summary',
                id: vm.patient.healthFacilityId
            }).$promise.then(facility => {
                vm.pecamEnabled = facility.features.includes('PECAM');
                if (vm.pecamEnabled) {
                    $log.debug('PECAM activé dans cet établissement');
                    vm.telemonitoringPrescriptionGenerationAvailable = facility.features.includes('TELEMONITORING_PRESCRIPTION_GENERATION');
                    Pecam.hasValidTelemonitoringPrescription(vm.patient.id).then(response => {
                        vm.hasValidTelemonitoringPrescription = response.data;
                    }).catch(() => {
                        vm.hasValidTelemonitoringPrescription = false;
                    });
                }
            });
        }

        function loadContractualTreatmentGroups() {
            $log.debug("Loading contractual treatment groups...");
            vm.contractualTreatmentGroups = {};
            ContractualTreatmentGroup.query({}, result => {
                $log.debug("Loaded contractual treatment groups.");
                angular.forEach(result, g => {
                    vm.contractualTreatmentGroups[g.id] = g.medicationIds;
                });
            });
        }

        function filterContractLine(contractLine) {
            return (!contractLine.diseaseId || contractLine.diseaseId === vm.selectedDisease.id)
                && matchTreatments(contractLine);
        }

        function matchTreatments(contractLine) {
            if(!contractLine.treatmentGroupId) {
                return true;
            }
            if (vm.contractualTreatmentGroups[contractLine.treatmentGroupId].length === 0) {
                return true;
            }
            for(const medication of vm.medications) {
                if (vm.contractualTreatmentGroups[contractLine.treatmentGroupId].includes(medication.id)) {
                    return true;
                }
            }
            return false;
        }

        function refreshPrograms() {
            vm.selectedProgram = null;
            if (!vm.contractLines) {
                $log.debug("Contract lines are not loaded, postponing refresh of programs.");
                needsRefreshPrograms = true;
                return;
            }

            const mapped = vm.contractLines
                .filter(filterContractLine)
                .map(contractLine => {
                    return {
                        uniqueKey: contractLine.programId + '-' + contractLine.trial,
                        id: contractLine.programId,
                        code: contractLine.programCode,
                        name: contractLine.programName,
                        description: contractLine.programDescription,
                        continuum: contractLine.programContinuum,
                        pro: contractLine.programPro,
                        trial: contractLine.trial
                    };
                });

            vm.programs = $filter('unique')(mapped, 'uniqueKey');

            $log.debug("Refreshed programs.", vm.programs);
        }

        function refreshSponsors() {
            vm.sponsors = [];

            if (!vm.selectedProgram)
                return;

            var contractLines = $filter('unique')(vm.contractLines
                .filter(filterContractLine)
                .filter(cl => cl.programId === vm.selectedProgram.id), 'customerId');
            if (contractLines.length === 1 && contractLines[0].customerId === -1) {
                vm.free = true;
            } else {
                vm.free = false;
                angular.forEach(contractLines, contractLine => {
                    if (contractLine.contractId > 0 && contractLine.customerName)
                        vm.sponsors.push(contractLine.customerName);
                });
            }
        }

        function refreshTherapies() {

            vm.requireTherapySelection = vm.selectedProgram && vm.selectedProgram.continuum;

            vm.requireTreatmentDay1Date = vm.selectedProgram
                && (vm.selectedProgram.continuum || vm.selectedProgram.code === 'picto');

            if (!vm.selectedProgram || !vm.requireTherapySelection) {
                vm.selectedTherapy = null;
                vm.therapies = null;
                vm.treatmentDay1Date = null;
                return;
            }

            const mapped = vm.contractLines
                .filter(filterContractLine)
                .filter(cl => cl.programId === vm.selectedProgram.id)
                .filter(cl => cl.trial === vm.selectedProgram.trial)
                .map(contractLine => {
                    return {
                        id: contractLine.therapyId,
                        prefix: prefixTherapy(contractLine),
                        name: contractLine.therapyName,
                        hah: contractLine.therapyHah,
                        hdj: contractLine.therapyHdj,
                        auto: contractLine.therapyAuto
                    };
                });

            vm.therapies = $filter('unique')(mapped, 'id');
            if (vm.therapies.length === 1) {
                vm.selectedTherapy = vm.therapies[0];
            }
        }

        function prefixTherapy(tcl) {
            if (tcl.therapyHah)
                return 'HAD / ';
            if (tcl.therapyHdj)
                return 'HDJ / ';
            return null;
        }

        function refreshMedicationPrescription() {

            if (!vm.selectedProgram) {
                vm.showMedicationPrescription = null;
                vm.suggestPharmacyOrder = null;
                vm.requireMedicationPrescription = null;
                vm.pharmacyOrder = null;
                vm.prescriptions.medication.mode = null;
                return;
            }

            if (vm.selectedProgram.continuum) {
                const hasOral = vm.medications.filter(m => m.routeType === 'ORAL').length > 0;
                vm.showMedicationPrescription = vm.selectedProgram.code !== 'hemavie';
                vm.suggestPharmacyOrder = hasOral && vm.selectedProgram.code !== 'hemavie';
                vm.requireMedicationPrescription = hasOral && !vm.isDoctor && vm.selectedProgram.code !== 'hemavie';
                if(!vm.suggestPharmacyOrder) {
                    vm.pharmacyOrder = false;
                    vm.prescriptions.medication.mode = null;
                }
            } else {
                vm.showMedicationPrescription = true;
                vm.suggestPharmacyOrder = false;
                vm.pharmacyOrder = false;
                vm.requireMedicationPrescription = false;
                vm.prescriptions.medication.mode = null;
            }

        }

        function refreshPecam() {

            if (!vm.selectedProgram) {
                vm.pecamAvailable = null;
                vm.pecam = null;
                return;
            }

            if (vm.pecamEnabled
                && vm.contractLines
                    .filter(filterContractLine)
                    .filter(cl => cl.programId === vm.selectedProgram.id)
                    .filter(cl => cl.trial === vm.selectedProgram.trial)
                    // TODO filter therapy?
                    .some(contractLine => contractLine.pecam)) {
                vm.pecamAvailable = true;
                vm.pecam = true;
            } else {
                vm.pecamAvailable = false;
                vm.pecam = false;
            }
            $log.debug('PECAM disponible ?', vm.pecamAvailable);
        }

        function addMedication() {
            vm.addTreatmentShown = true;
            $uibModal.open({
                templateUrl: 'app/activity/patient-therapy/patient-therapy-medication-add-dialog.html',
                controller: 'PatientTherapyMedicationAddDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    options: () => ({
                        patientId: vm.patient.id,
                        search: true,
                        restrict: vm.medications.length === 0 && !vm.allowAllTreatments,
                        diseaseId: vm.selectedDisease && vm.selectedDisease.id,
                        exclusions: vm.medications.map(medication => medication.id)
                    })
                }
            }).result.then(medication => {
                vm.showTreatmentsBlock = true;
                vm.medications.push(medication);
                refreshPrograms();
                $timeout(() => {
                    angular.element('#btn-add-medication').focus();
                });
            }, () => {
                vm.showTreatmentsBlock = true;
            });
        }

        function removeMedicationAtIndex(index) {
            vm.medications.splice(index, 1);
            refreshPrograms();
        }

        function initPrescription() {
            return {
                mode: null,
                file: null,
                media: null,
                uploading: false,
                uploadProgress: null,
                importError: null,
                prescriberId: null,
                date: moment().startOf('day').toDate(),
            };
        }

        function compressUploadPrescription(prescription, file) {
            if (file.type.startsWith('image/')) {
                CompressImage.compress(file).then((compressedImageFile) => {
                    uploadPrescription(prescription, compressedImageFile);
                });
            } else {
                uploadPrescription(prescription, file);
            }
        }

        function uploadPrescription(prescription, file) {
            if (vm.prescriptions[prescription].uploading) {
                return;
            }
            vm.prescriptions[prescription].uploadProgress = null;
            vm.prescriptions[prescription].importError = null;
            if (!file) {
                return;
            }
            vm.prescriptions[prescription].file = file;
            vm.prescriptions[prescription].uploading = true;
            Upload.upload({
                url: 'api/media',
                method: 'POST',
                arrayKey: '',
                data: {
                    patientId: vm.patient.id,
                    draft: true,
                    file
                }
            })
                .then(
                    response => {
                        console.log('1st', response);
                        vm.prescriptions[prescription].media = response.data;
                    },
                    response => {
                        console.log('2nd', response);
                        if (response.data)
                            vm.prescriptions[prescription].importError = response.data.detail;
                    },
                    evt => vm.prescriptions[prescription].uploadProgress = Math.round(100 * evt.loaded / evt.total))
                .finally(
                    () => vm.prescriptions[prescription].uploading = false);
        }

        function showPrescriptionMedia(prescription) {
            $uibModal.open({
                templateUrl: 'app/activity/media/media-preview.modal.html',
                controller: 'MediaPreviewModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    media: () => vm.prescriptions[prescription].media,
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function deletePrescriptionFile(prescription) {
            if (!vm.prescriptions[prescription].media) {
                return;
            }
            Media.delete({ id: vm.prescriptions[prescription].media.id });
            vm.prescriptions[prescription].file = null;
            vm.prescriptions[prescription].media = null;
            vm.prescriptions[prescription].uploadProgress = null;
        }

    }
})();
